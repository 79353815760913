// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import SEO from '../../components/seo'
import SolutionsPage from '../../components/SolutionsPage'

import messages from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleSolutionsPage)}
        description={formatMessage(metaDescription.SolutionsPageDescription)}
        lang={locale}
      />
      <SolutionsPage />
    </Layout>
  )
}

export default IndexPage
