// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import ScrollAnimation from 'react-animate-on-scroll'
import { StaticImage } from 'gatsby-plugin-image'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import CaseTeasers from '../../components/CaseStudy/CaseTeasers'
import ProductTeaser from '../ProductTeaser'
import Services from '../../components/Services'
import Solutions from '../../components/Solutions'
import Clients from '../../components/Clients'
import Products from '../../components/Products'
import Scrollindicator from '../../components/basic/Scrollindicator'
import baseStyles from './SolutionsPage.scss'
import messages from './SolutionsPage.lang'

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/service_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const SolutionsPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser title={formatMessage(messages.pageTitle)} image={HeroTeaserImage} imgAlt="office gallery" />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <Solutions hideTitle />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <Services />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Clients defaultTitle />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <CaseTeasers
          list={['dertouristik', 'plattenplaner', 'lhh', 'etravel', 'aldiana', 'weekend', 'bedfinder']}
          allButton
        />
      </ScrollAnimation>
      <Scrollindicator />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Products type="quickstart" />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <ProductTeaser />
      </ScrollAnimation>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(SolutionsPage)
